export const setEmpleados = (state, empleados) => {
  state.empleados = empleados
}

export const setBusqueda = (state, busqueda) => {
  state.busqueda = busqueda
}

export const setLoading = (state, loading) => {
  state.loading = loading
}

export const setVista = (state, vista) => {
  state.vista = vista
}

export const setFormaBusqueda = (state, forma) => {
  state.formaBusqueda = forma
}
