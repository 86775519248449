export default {
  component: () => import("../../../template/Index"),
  children: [
    {
      path: "",
      name: "proyectos",
      component: () => import("../components/Proyectos"),
    },
    {
      path: "nuevo",
      name: "proyectos-alta",
      component: () => import("../components/ProyectosForma"),
    },
    {
      path: "editar/:cid",
      name: "proyectos-editar",
      props: (route) => {
        const cid = route.params.cid
        return {cid}
      },
      component: () => import("../components/ProyectosForma"),
    },
  ],
}
