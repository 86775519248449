export const proyectos = (state) => {
  return state.proyectos
}
export const turnos = (state) => {
  return state.turnos
}

export const horarios = (state) => {
  return state.horarios
}

export const uniformes = (state) => {
  return state.uniformes
}

export const clientes = (state) => {
  return state.clientes
}

export const contactos = (state) => {
  return state.contactos
}

export const disponibles = (state) => {
  return state.disponibles
}

export const busqueda = (state) => {
  return state.busqueda
}

export const motivos = (state) => {
  return state.motivos
}

export const loading = (state) => {
  return state.loading
}
