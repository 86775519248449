export const setProyectos = (state, proyectos) => {
  state.proyectos = proyectos
}

export const setTurnos = (state, turnos) => {
  state.turnos = turnos
}

export const setHorarios = (state, horarios) => {
  state.horarios = horarios
}

export const setUniformes = (state, uniformes) => {
  state.uniformes = uniformes
}

export const setClientes = (state, clientes) => {
  state.clientes = clientes
}

export const setContactos = (state, contactos) => {
  state.contactos = contactos
}

export const setDisponibles = (state, disponibles) => {
  state.disponibles = disponibles
}

export const setBusqueda = (state, busqueda) => {
  state.busqueda = busqueda
}

export const setMotivos = (state, motivos) => {
  state.motivos = motivos
}

export const setLoading = (state, loading) => {
  state.loading = loading
}
