export default {
  component: () => import("../../../template/Index"),
  children: [
    {
      path: "",
      name: "catalogos",
      component: () => import("../components/Catalogos"),
    },
  ],
}
