import api from "@/api"

export const setActiveSubMenu = ({commit}, menu) => {
  commit("setActiveSubMenu", menu)
}

export const getEmpleados = async ({commit}, filtros) => {
  commit("setLoading", true)
  const {data} = await api.post("empleados-listado", filtros)
  commit("setBusqueda", true)
  commit("setLoading", false)
  commit("setFormaBusqueda", filtros)
  if (data.ok) {
    commit("setEmpleados", data.empleados)
  } else {
  }
}

export const setLimpiarBusqueda = async ({commit}, forma) => {
  commit("setLoading", false)
  commit("setBusqueda", false)
  commit("setEmpleados", [])
  commit("setFormaBusqueda", forma)
}

export const setVista = ({commit}, vista) => {
  commit("setVista", vista)
}

export const getEmpleadoInfo = async ({commit}, empleado) => {
  const {data} = await api.post("empleados-info", {empleado})
  return data
}
