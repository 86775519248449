export default () => ({
  loading: false,
  proyectos: [],
  turnos: [],
  horarios: [],
  uniformes: [],
  clientes: [],
  contactos: [],
  disponibles: [],
  motivos: [],
  busqueda: {
    filtro: "",
  },
})
