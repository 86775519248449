export const setUsuarios = (state, usuarios) => {
  state.usuarios = usuarios
}

export const setLoading = (state, loading) => {
  state.loading = loading
}

export const setBusqueda = (state, busqueda) => {
  state.busqueda = busqueda
}

export const setMenus = (state, menus) => {
  state.menus = menus
}
