import api from "@/api"

export const getUsuarios = async ({commit}, busqueda) => {
  commit("setLoading", true)
  commit("setBusqueda", busqueda)
  const {data} = await api.post("usuarios-listado", busqueda)
  commit("setLoading", false)
  if (data.ok) {
    commit("setUsuarios", data.usuarios)
  }

  return data
}

export const setBusqueda = ({commit}, busqueda) => {
  commit("setBusqueda", busqueda)
}

export const getUsuarioInfo = async ({commit}, usuario) => {
  const {data} = await api.post("usuarios-info", usuario)
  return data
}

export const setGuardarUsuario = async ({commit}, usuario) => {
  const {data} = await api.post("usuarios-guardar", usuario)
  return data
}

export const setEliminarUsuario = async ({commit}, usuario) => {
  const {data} = await api.post("usuarios-eliminar", usuario)
  return data
}

export const getUsuariosCatalogos = async ({commit}) => {
  const {data} = await api.post("usuarios-catalogos")
  if (data.ok) {
    commit("setMenus", data.menus)
  } else {
    commit("setTipos", [])
  }
}
