import api from "@/api"

export const loginUser = async ({commit}, user) => {
  try {
    commit("setLoading", true)
    const {data} = await api.post("login", user)
    if (data.ok) {
      commit("loginUser", data.user)
      commit("setMenus", data.menus)
      commit("setLoading", false)
      return {ok: true, message: "UserLogin"}
    } else {
      commit("setLoading", false)
      return {ok: false, message: data.message}
    }
  } catch (error) {
    commit("setLoading", false)
    return {ok: false, message: error}
  }
}

export const lostPassword = async ({commit}, email) => {
  commit("setLostLoading", true)
  try {
    const {data} = await api.post("lost-password-token", {email})
    commit("setLostLoading", false)
    return data
  } catch (error) {
    commit("setLostLoading", false)
    return {ok: false, message: error}
  }
}

export const loginUserCode = async ({commit}, user) => {
  try {
    commit("setCodeLoading", true)
    const {data} = await api.post("lost-password-login", user)
    if (data.ok) {
      commit("loginUser", data)
      commit("setCodeLoading", false)
      return {ok: true, message: "UserLogin"}
    } else {
      commit("setCodeLoading", false)
      return {ok: false, message: data.message}
    }
  } catch (error) {
    commit("setCodeLoading", false)
    return {ok: false, message: error}
  }
}

export const authCheck = async ({commit}, module) => {
  try {
    const token = localStorage.getItem("karpe-api-token")
    if (!token) {
      commit("logoutUser")
      return {ok: false, message: "Token mismatch"}
    }

    const {data} = await api.post("auth-check", {module})
    if (data.ok) {
      return {...data}
    } else {
      commit("logoutUser")
      return {...data}
    }
  } catch (error) {
    commit("logoutUser")
    return {ok: false, message: error}
  }
}

export const logoutUser = async ({commit}) => {
  commit("logoutUser")
}
