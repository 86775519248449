export const clientes = (state) => {
  return state.clientes
}

export const loading = (state) => {
  return state.loading
}

export const busqueda = (state) => {
  return state.busqueda
}

export const tipos = (state) => {
  return state.tipos
}
