export const setClientes = (state, clientes) => {
  state.clientes = clientes
}

export const setLoading = (state, loading) => {
  state.loading = loading
}

export const setBusqueda = (state, busqueda) => {
  state.busqueda = busqueda
}

export const setTipos = (state, tipos) => {
  state.tipos = tipos
}
