export const setActiveMenu = (state, menu) => {
  state.activeMenu = menu
}

export const setActiveSubMenu = (state, menu) => {
  state.activeSubMenu = menu
}

export const setCiviles = (state, civiles) => {
  state.civiles = civiles
}

export const setDocumentos = (state, documentos) => {
  state.documentos = documentos
}

export const setEscolaridades = (state, escolaridades) => {
  state.escolaridades = escolaridades
}

export const setGeneros = (state, generos) => {
  state.generos = generos
}

export const setMedios = (state, medios) => {
  state.medios = medios
}

export const setAprendizaje = (state, aprendizaje) => {
  state.aprendizaje = aprendizaje
}

export const setCuestionario = (state, cuestionario) => {
  state.cuestionario = cuestionario
}

export const setPersonalidad = (state, personalidad) => {
  state.personalidad = personalidad
}

export const setPuntualidad = (state, puntualidad) => {
  state.puntualidad = puntualidad
}

export const setSugeridos = (state, sugeridos) => {
  state.sugeridos = sugeridos
}

export const setPuestos = (state, puestos) => {
  state.puestos = puestos
}

export const setVive = (state, vive) => {
  state.vive = vive
}

export const setEjecutivos = (state, ejecutivos) => {
  state.ejecutivos = ejecutivos
}
