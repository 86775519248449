export default () => ({
  activeMenu: null,
  activeSubMenu: null,
  civiles: [],
  documentos: [],
  escolaridades: [],
  generos: [],
  medios: [],
  aprendizaje: [],
  cuestionario: [],
  personalidad: [],
  puntualidad: [],
  sugeridos: [],
  puestos: [],
  vive: [],
  ejecutivos: [],
})
