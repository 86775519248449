import api from "@/api"

export const getClientes = async ({commit}, busqueda) => {
  commit("setLoading", true)
  commit("setBusqueda", busqueda)
  const {data} = await api.post("clientes-listado", busqueda)
  commit("setLoading", false)
  if (data.ok) {
    commit("setClientes", data.clientes)
  }

  return data
}

export const setBusqueda = ({commit}, busqueda) => {
  commit("setBusqueda", busqueda)
}

export const getClienteInfo = async ({commit}, cliente) => {
  const {data} = await api.post("clientes-info", cliente)
  return data
}

export const setGuardarCliente = async ({commit}, cliente) => {
  const {data} = await api.post("clientes-guardar", cliente)
  return data
}

export const setEliminarCliente = async ({commit}, cliente) => {
  const {data} = await api.post("clientes-eliminar", cliente)
  return data
}

export const getClientesCatalogos = async ({commit}) => {
  const {data} = await api.post("clientes-catalogos")
  if (data.ok) {
    commit("setTipos", data.tipos)
  } else {
    commit("setTipos", [])
  }
}
