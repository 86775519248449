<template>
  <router-view />
</template>

<script>
export default {
  name: "App",
  components: {},
};
</script>

<style>
.card .card-header {
  min-height: 10px;
}

.card .card-header .card-title,
.card .card-header .card-title .card-label {
  font-size: 1.1rem;
}

.card .card-body {
  padding: 1rem 1.25rem;
  color: var(--kt-card-color);
}

.card .card-header .card-title-form {
  display: flex;
  align-items: center;
  margin: 0.5rem;
  margin-left: 0;
}

.modal-header {
  padding: 5px 20px;
}

.modal-footer {
  padding: 8px;
}

.btn-group-xs
  > .btn:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon),
.btn:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon).btn-xs {
  padding: 5px 8px 5px 8px;
}
</style>
