export const activeMenu = (state) => {
  return state.activeMenu
}

export const activeSubMenu = (state) => {
  return state.activeSubMenu
}

export const civiles = (state) => {
  return state.civiles
}

export const documentos = (state) => {
  return state.documentos
}

export const escolaridades = (state) => {
  return state.escolaridades
}

export const generos = (state) => {
  return state.generos
}

export const medios = (state) => {
  return state.medios
}

export const aprendizaje = (state) => {
  return state.aprendizaje
}

export const cuestionario = (state) => {
  return state.cuestionario
}

export const personalidad = (state) => {
  return state.personalidad
}

export const puntualidad = (state) => {
  return state.puntualidad
}

export const sugeridos = (state) => {
  return state.sugeridos
}

export const puestos = (state) => {
  return state.puestos
}

export const vive = (state) => {
  return state.vive
}

export const ejecutivos = (state) => {
  return state.ejecutivos
}
