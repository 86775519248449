export const usuarios = (state) => {
  return state.usuarios
}

export const loading = (state) => {
  return state.loading
}

export const busqueda = (state) => {
  return state.busqueda
}

export const menus = (state) => {
  return state.menus
}
