import {createStore} from "vuex"
import auth from "../modules/auth/store"
import template from "../modules/template/store"
import empleados from "../modules/empleados/store"
import clientes from "../modules/clientes/store"
import usuarios from "../modules/usuarios/store"
import proyectos from "../modules/proyectos/store"
import createPersistedState from "vuex-persistedstate"

const store = createStore({
  modules: {
    auth,
    template,
    empleados,
    clientes,
    usuarios,
    proyectos,
  },
  plugins: [createPersistedState({key: "karpe-persist"})],
})

export default store
