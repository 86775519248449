import api from "@/api"

export const getProyectoInfo = async ({commit}, proyecto) => {
  commit("setLoading", true)
  const {data} = await api.post("proyectos-info", {proyecto})
  commit("setLoading", false)
  return data
}

export const getProyectoCatalogos = async ({commit}) => {
  const {data} = await api.post("proyectos-catalogos")
  if (data.ok) {
    commit("setTurnos", data.turnos)
    commit("setHorarios", data.horarios)
    commit("setUniformes", data.uniformes)
    commit("setClientes", data.clientes)
    commit("setContactos", data.contactos)
    commit("setDisponibles", data.disponibles)
    commit("setMotivos", data.motivos)
  }
}

export const setGuardarProyecto = async ({commit}, proyecto) => {
  const {data} = await api.post("proyectos-guardar", proyecto)
  return data
}

export const getProyectos = async ({commit}, busqueda) => {
  commit("setBusqueda", busqueda)
  const {data} = await api.post("proyectos-listado", busqueda)
  if (data.ok) {
    commit("setProyectos", data.proyectos)
  } else {
    commit("setProyectos", [])
  }
}

export const setEliminarProyecto = async ({commit}, proyecto) => {
  const {data} = await api.post("proyectos-eliminar", {proyecto})
  return data
}
