export default {
  component: () => import("../../../template/Index"),
  children: [
    {
      path: "",
      name: "dashboard",
      component: () => import("../components/Dashboard"),
    },
  ],
}
