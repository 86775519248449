export const loginUser = (state, {nombre, token, email, foto}) => {
  if (token) {
    localStorage.setItem("karpe-api-token", token)
    state.token = token
    state.nombre = nombre
    state.email = email
    state.foto = foto
  }
}

export const setMenus = (state, menus) => {
  state.menus = menus
}

export const logoutUser = (state) => {
  state.email = null
  state.foto = null
  state.email = null
  state.token = null
  localStorage.removeItem("karpe-api-token")
}

export const setNombre = (state, nombre) => {
  state.nombre = nombre
}

export const setEmail = (state, email) => {
  state.email = email
}

export const setFoto = (state, foto) => {
  state.foto = foto
}

export const setLoading = (state, loading) => {
  state.loading = loading
}

export const setLostLoading = (state, loading) => {
  state.lostLoading = loading
}

export const setCodeLoading = (state, loading) => {
  state.codeLoading = loading
}
