export default () => ({
  empleados: [],
  busqueda: false,
  loading: false,
  vista: "cuadros",
  formaBusqueda: {
    filtro: "",
    genero: "",
    domicilio: "",
    puesto: "",
    escolaridad: "",
    proyecto: "",
  },
})
