import api from "@/api"

export const setActiveMenu = ({commit}, menu) => {
  commit("setActiveMenu", menu)
}

export const setActiveSubMenu = ({commit}, menu) => {
  commit("setActiveSubMenu", menu)
}

export const getCatalogos = async ({commit}) => {
  const {data} = await api.post("empleados-catalogos")
  if (data.ok) {
    const catalogo = data.catalogos

    commit("setCiviles", catalogo.civiles)
    commit("setDocumentos", catalogo.documentos)
    commit("setEscolaridades", catalogo.escolaridades)
    commit("setGeneros", catalogo.generos)
    commit("setMedios", catalogo.medios)
    commit("setAprendizaje", catalogo.aprendizaje)
    commit("setCuestionario", catalogo.cuestionario)
    commit("setPersonalidad", catalogo.personalidad)
    commit("setPuntualidad", catalogo.puntualidad)
    commit("setSugeridos", catalogo.sugeridos)
    commit("setPuestos", catalogo.puestos)
    commit("setVive", catalogo.vive)
    commit("setEjecutivos", catalogo.ejecutivos)
  }
}
