import {createRouter, createWebHistory} from "vue-router"
import dashboard from "../modules/dashboard/router"
import auth from "../modules/auth/router"
import isAuth from "../modules/auth/router/auth-guard"
import clientes from "../modules/clientes/router"
import usuarios from "../modules/usuarios/router"
import catalogos from "../modules/catalogos/router"
import cotizaciones from "../modules/cotizaciones/router"
import empleados from "../modules/empleados/router"
import proyectos from "../modules/proyectos/router"

const routes = [
  {
    path: "/",
    ...dashboard,
    beforeEnter: [isAuth],
  },
  {path: "/empleados", ...empleados, beforeEnter: [isAuth]},
  {path: "/clientes", ...clientes, beforeEnter: [isAuth]},
  {path: "/usuarios", ...usuarios, beforeEnter: [isAuth]},
  {path: "/catalogos", ...catalogos, beforeEnter: [isAuth]},
  {path: "/cotizaciones", ...cotizaciones, beforeEnter: [isAuth]},
  {path: "/proyectos", ...proyectos, beforeEnter: [isAuth]},
  {
    path: "/login",
    ...auth,
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/login",
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
