export const nombre = (state) => {
  return state.nombre
}
export const token = (state) => {
  return state.token
}
export const email = (state) => {
  return state.email
}
export const foto = (state) => {
  return state.foto
}
export const loading = (state) => {
  return state.loading
}

export const lostLoading = (state) => {
  return state.lostLoading
}

export const codeLoading = (state) => {
  return state.codeLoading
}

export const menus = (state) => {
  return state.menus
}
