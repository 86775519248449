import store from "@/store"

const isAuth = async (to, from, next) => {
  const {ok} = await store.dispatch("auth/authCheck", to.name)
  if (ok) {
    next()
  } else {
    next({name: "login"})
  }
}

export default isAuth
