export default () => ({
  nombre: "",
  token: "",
  email: "",
  foto: "",
  loading: false,
  lostLoading: false,
  codeLoading: false,
  menus: [],
})
