export const activeMenu = (state) => {
  return state.activeMenu
}

export const empleados = (state) => {
  return state.empleados
}

export const busqueda = (state) => {
  return state.busqueda
}

export const loading = (state) => {
  return state.loading
}

export const vista = (state) => {
  return state.vista
}

export const formaBusqueda = (state) => {
  return state.formaBusqueda
}
